import React from "react";
import { Footer } from "../../components";

export const NotFoundScreen = () => {

  return (
    <>
        <div className="bg-black text-white font-inter antialiased dark:bg-black dark:text-white min-h-screen flex flex-col justify-center items-center">
        {/* Dark glowing background and animated effects */}
        <div className="absolute inset-0 bg-gradient-to-r from-purple-700 via-teal-500 to-blue-500 opacity-30 "></div>

        {/* Main content */}
        <section className="z-10 relative text-center py-16 px-4">
            <div className="space-y-8">
            <h1 className="text-6xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-purple-500 animate__animated animate__fadeIn">
                Oops! This page took a wrong turn. 🚀
            </h1>
            <p className="text-lg text-gray-400 max-w-2xl mx-auto">
                Looks like you’ve ventured into the unknown. Let’s get you back to safety before you drift too far in the Web3 abyss.
            </p>
            <a
                href="/"
                className="inline-flex items-center px-6 py-3 mt-8 text-white font-medium rounded-full bg-gradient-to-r from-teal-500 to-blue-500 hover:scale-105 transform transition duration-300 ease-in-out shadow-xl hover:from-teal-400 hover:to-blue-400"
            >
                <span>Back to Home</span>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 13l4-4m0 0l-4-4m4 4H3"
                />
                </svg>
            </a>
            </div>
        </section>

        {/* Floating 3D Cubes Animation */}
        <div className="absolute inset-0 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
            <div className="absolute -top-24 left-1/2 transform -translate-x-1/2 animate-spin-slow">
            <div className="w-16 h-16 bg-gradient-to-r from-teal-500 to-blue-500 rounded-lg shadow-lg opacity-75"></div>
            </div>
            <div className="absolute top-24 left-1/4 transform -translate-x-1/2 animate-spin-slow">
            <div className="w-16 h-16 bg-gradient-to-r from-purple-500 to-teal-500 rounded-lg shadow-lg opacity-75"></div>
            </div>
            <div className="absolute top-24 right-1/4 transform -translate-x-1/2 animate-spin-slow">
            <div className="w-16 h-16 bg-gradient-to-r from-blue-500 to-teal-500 rounded-lg shadow-lg opacity-75"></div>
            </div>
        </div>
        </div>
        {/* Footer */}
        <Footer />
    </>
  );
};
