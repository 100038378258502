import React from 'react';
import { Footer } from '../../components';

const HomeScreen: React.FC = () => {
  return (
    <>
      <div className="h-screen flex justify-center items-center bg-gradient-to-r from-cyan-500 to-blue-700">
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="relative text-center text-white z-10 px-6 md:px-12">
          {/* Logo with animation */}
          <img 
            src={require('../../assets/images/logo.png')} 
            alt="Logo" 
            className="w-full h-32 md:w-full md:h-28 mx-auto mb-6 animate-bounce"
          />
          
          <h1 className="text-4xl md:text-6xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 animate-pulse">
            🚧 Under Construction 🚧
          </h1>
          
          <p className="text-lg md:text-2xl mt-4">
            We're building something amazing. Stay tuned!
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeScreen;
