import React from "react";

export const Footer = () => {
    return (
        <footer className="relative bg-white border-t  border-gray-200 -mt-px">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-16">
                    {/* Footer illustration */}
                    <div className="pointer-events-none -z-1" aria-hidden="true">
                        <svg className="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 opacity-40" width="800" height="264" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="400" cy="400" r="400" fill="url(#footerglow_paint0_radial)" fillOpacity=".4" />
                            <defs>
                            <radialGradient id="footerglow_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 0 400) scale(315.089)">
                                <stop stopColor="#3ABAB4" />
                                <stop offset="1" stopColor="#3ABAB4" stopOpacity=".01" />
                            </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    
                    {/* Top area: Blocks */}
                    <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
                    
                        {/* 1st block */}
                        <div className="md:col-span-2 lg:col-span-3">
                            {/* Logo */}
                            <a className="inline-block" href="./" aria-label="YouCrew">
                                <img className="w-[200px] " src={require('../../assets/images/logo-dark.png')} alt="YouCrew logo"/>
                            </a>
                            <br/>
                            <a 
                                className=" text-gray-400 hover:underline transition duration-150 ease-in-out mt-10" 
                                href="mailto:info@youcrew.com.au"
                            >info@youcrew.com.au</a>
                        </div>
        
                        <div className="md:col-span-10 lg:col-span-9 grid grid-cols-2 md:grid-cols-4 gap-8">
            
                            {/* 5th block */}
                            <div className="text-sm">
                                <h6 className="font-medium uppercase mb-2">Legals</h6>
                                <ul>
                                    <li className="mb-1">
                                        <a className=" text-gray-400 hover:underline transition duration-150 ease-in-out" href="/terms-and-conditions">Terms & Conditions</a>
                                    </li>
                                    <li className="mb-1">
                                        <a className=" text-gray-400 hover:underline transition duration-150 ease-in-out" href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li className="mb-1">
                                        <a className=" text-gray-400 hover:underline transition duration-150 ease-in-out" href="/eula">End User License Agreement</a>
                                    </li>
                                    <li className="mb-1">
                                        <a className=" text-gray-400 hover:underline transition duration-150 ease-in-out" href="/disclaimer">Disclaimer</a>
                                    </li>
                                    <li className="mb-1">
                                        <a className="text-gray-400 hover:underline transition duration-150 ease-in-out" href="/acceptable-use-policy">Acceptable Use Policy</a>
                                    </li>
                                </ul>
                            </div>
            
                        </div>
                    </div>
        
                    {/* Bottom area */}
                    <div className="md:flex md:items-center md:justify-between">
                    
                        {/* Social links */}
                        <ul className="flex mb-4 md:order-2 md:ml-4 md:mb-0">
                            <li>
                                <a 
                                    className="flex justify-center items-center  bg-teal-500  hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" 
                                    href="https://www.facebook.com/youcrewapp"
                                    aria-label="linkedin"
                                >
                                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                            <li className="ml-4">
                                <a 
                                    className="flex justify-center items-center  bg-teal-500  hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" 
                                    href="https://www.instagram.com/youcrewapp"
                                    aria-label="linkedin"
                                >
                                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20.145" cy="11.892" r="1" fill="#fff"/>
                                        <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" fill="#fff" />
                                        <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                            <li className="ml-4">
                                <a 
                                    className="flex justify-center items-center bg-teal-500  hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" 
                                    href="https://www.linkedin.com/company/youcrewapp" 
                                    aria-label="linkedin"
                                >
                                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" fill="#fff"/>
                                    </svg>
                                </a>
                            </li>
                            <li className="ml-4">
                                <a 
                                    className="flex justify-center items-center  bg-teal-500  hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" 
                                    href="https://www.tiktok.com/@youcrewapp" 
                                    aria-label="tiktok"
                                >
                                <svg className="w-8 h-8 fill-current" viewBox="0 -5 32 46" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.45095 19.7926C8.60723 18.4987 9.1379 17.7743 10.1379 17.0317C11.5688 16.0259 13.3561 16.5948 13.3561 16.5948V13.2197C13.7907 13.2085 14.2254 13.2343 14.6551 13.2966V17.6401C14.6551 17.6401 12.8683 17.0712 11.4375 18.0775C10.438 18.8196 9.90623 19.5446 9.7505 20.8385C9.74562 21.5411 9.87747 22.4595 10.4847 23.2536C10.3345 23.1766 10.1815 23.0889 10.0256 22.9905C8.68807 22.0923 8.44444 20.7449 8.45095 19.7926ZM22.0352 6.97898C21.0509 5.90039 20.6786 4.81139 20.5441 4.04639H21.7823C21.7823 4.04639 21.5354 6.05224 23.3347 8.02482L23.3597 8.05134C22.8747 7.7463 22.43 7.38624 22.0352 6.97898ZM28 10.0369V14.293C28 14.293 26.42 14.2312 25.2507 13.9337C23.6179 13.5176 22.5685 12.8795 22.5685 12.8795C22.5685 12.8795 21.8436 12.4245 21.785 12.3928V21.1817C21.785 21.6711 21.651 22.8932 21.2424 23.9125C20.709 25.246 19.8859 26.1212 19.7345 26.3001C19.7345 26.3001 18.7334 27.4832 16.9672 28.28C15.3752 28.9987 13.9774 28.9805 13.5596 28.9987C13.5596 28.9987 11.1434 29.0944 8.96915 27.6814C8.49898 27.3699 8.06011 27.0172 7.6582 26.6277L7.66906 26.6355C9.84383 28.0485 12.2595 27.9528 12.2595 27.9528C12.6779 27.9346 14.0756 27.9528 15.6671 27.2341C17.4317 26.4374 18.4344 25.2543 18.4344 25.2543C18.5842 25.0754 19.4111 24.2001 19.9423 22.8662C20.3498 21.8474 20.4849 20.6247 20.4849 20.1354V11.3475C20.5435 11.3797 21.2679 11.8347 21.2679 11.8347C21.2679 11.8347 22.3179 12.4734 23.9506 12.8889C25.1204 13.1864 26.7 13.2483 26.7 13.2483V9.91314C27.2404 10.0343 27.7011 10.0671 28 10.0369Z" fill="#fff"/>
                                    <path d="M26.7009 9.91314V13.2472C26.7009 13.2472 25.1213 13.1853 23.9515 12.8879C22.3188 12.4718 21.2688 11.8337 21.2688 11.8337C21.2688 11.8337 20.5444 11.3787 20.4858 11.3464V20.1364C20.4858 20.6258 20.3518 21.8484 19.9432 22.8672C19.4098 24.2012 18.5867 25.0764 18.4353 25.2553C18.4353 25.2553 17.4337 26.4384 15.668 27.2352C14.0765 27.9539 12.6788 27.9357 12.2604 27.9539C12.2604 27.9539 9.84473 28.0496 7.66995 26.6366L7.6591 26.6288C7.42949 26.4064 7.21336 26.1717 7.01177 25.9257C6.31777 25.0795 5.89237 24.0789 5.78547 23.7934C5.78529 23.7922 5.78529 23.791 5.78547 23.7898C5.61347 23.2937 5.25209 22.1022 5.30147 20.9482C5.38883 18.9122 6.10507 17.6625 6.29444 17.3494C6.79597 16.4957 7.44828 15.7318 8.22233 15.0919C8.90538 14.5396 9.6796 14.1002 10.5132 13.7917C11.4144 13.4295 12.3794 13.2353 13.3565 13.2197V16.5948C13.3565 16.5948 11.5691 16.028 10.1388 17.0317C9.13879 17.7743 8.60812 18.4987 8.45185 19.7926C8.44534 20.7449 8.68897 22.0923 10.0254 22.991C10.1813 23.0898 10.3343 23.1775 10.4845 23.2541C10.7179 23.5576 11.0021 23.8221 11.3255 24.0368C12.631 24.8632 13.7249 24.9209 15.1238 24.3842C16.0565 24.0254 16.7586 23.2167 17.0842 22.3206C17.2888 21.7611 17.2861 21.1978 17.2861 20.6154V4.04639H20.5417C20.6763 4.81139 21.0485 5.90039 22.0328 6.97898C22.4276 7.38624 22.8724 7.7463 23.3573 8.05134C23.5006 8.19955 24.2331 8.93231 25.1734 9.38216C25.6596 9.61469 26.1722 9.79285 26.7009 9.91314Z" fill="#fff"/>
                                    <path d="M4.48926 22.7568V22.7594L4.57004 22.9784C4.56076 22.9529 4.53074 22.8754 4.48926 22.7568Z" fill="#fff"/>
                                </svg>
                                </a>
                            </li>
                        </ul>
            
                        {/* Copyright */}
                        <div className="text-sm  text-gray-400">
                            <span className="block mb-2">&copy; {new Date().getFullYear()} HANDY FIND PTY LTD. All rights reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}